import React from 'react';
import styles from './home.module.css';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import withNavigateHook from '../../../customer/components/withNavigateHook';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

/*const testimonial = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};*/

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className={`${styles.BannerSec}`}>
          <Carousel className={`${styles.BannerSlide}`}
            swipeable={true}
            arrows={false}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={props.deviceType !== "mobile" ? true : false}
            autoPlay={false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="2000ms all"
            transitionDuration={400}>

            <div className={`${styles.item}`}>
              <div className={`${styles.Banner}`}>
                <img src="/images/banner_img_1.jpg" className={`${styles.BannerBG}`} alt="" />
                <div className={`${styles.Container}`}>
                  <div className={`${styles.BannerContent}`}>
                    <div className={`${styles.BannerText}`}>
                      <p className={`${styles.BannerTitle} ${styles.Heading}`}>Strongest Distribution Network</p>
                      <p className={`${styles.BannerText}`}>Global turnkey logistics and transportation services via sea, land and air. We will protect you from risk and liability.</p>
                      <button className={`${styles.HomeBU}`}>Learn More</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.item}`}>
              <div className={`${styles.Banner}`}>
                <img src="/images/banner_img_2.jpg" className={`${styles.BannerBG}`} alt="" />
                <div className={`${styles.Container}`}>
                  <div className={`${styles.BannerContent}`}>
                    <div className={`${styles.BannerText}`}>
                      <p className={`${styles.BannerTitle} ${styles.Heading}`}>Strongest Distribution Network</p>
                      <p className={`${styles.BannerText}`}>Global turnkey logistics and transportation services via sea, land and air. We will protect you from risk and liability.</p>
                      <button className={`${styles.HomeBU}`}>Learn More</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.item}`}>
              <div className={`${styles.Banner}`}>
                <img src="/images/banner_img_3.jpg" className={`${styles.BannerBG}`} alt="" />
                <div className={`${styles.Container}`}>
                  <div className={`${styles.BannerContent}`}>
                    <div className={`${styles.BannerText}`}>
                      <p className={`${styles.BannerTitle} ${styles.Heading}`}>Strongest Distribution Network</p>
                      <p className={`${styles.BannerText}`}>Global turnkey logistics and transportation services via sea, land and air. We will protect you from risk and liability.</p>
                      <button className={`${styles.HomeBU}`}>Learn More</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Carousel>
        </div>

        <div className={`${styles.FeatresSec}`}>
          <img className={`${styles.thumbnail}`} src="/images/world-map.png" alt="" />
          <div className={`${styles.Container} ${styles.FeatresPosition}`}>
            <div className={`${styles.Row}`}>

              <div className={`${styles.OneThird}`}>
                <div className={`${styles.OneThirdUnder}`}>
                  <img src="/images/freturesImg2.jpg" className={`${styles.freturesImg}`} alt="" />
                  <div className={`${styles.freturesCon}`}>
                    <p className={`${styles.freturesConTitle} ${styles.Heading}`}>OVERLAND NETWORK</p>
                    <p className={`${styles.freturesConText}`}>We have a wide experience in overland industry
                      specific logistic solutions like farmaceutical
                      logistics, retail and automotive logistics by train or
                      road.</p>
                  </div>
                </div>
              </div>

              <div className={`${styles.OneThird}`}>
                <div className={`${styles.OneThirdUnder}`}>
                  <img src="/images/freturesImg1.jpg" className={`${styles.freturesImg}`} alt="" />
                  <div className={`${styles.freturesCon}`}>
                    <p className={`${styles.freturesConTitle} ${styles.Heading}`}>OCEAN FREIGHT</p>
                    <p className={`${styles.freturesConText}`}>We bring your goods safely to worldwide
                      destinations with our great sea fright services. We
                      offer LLC and FLC shipments that are fast and
                      effective with no delays.</p>
                  </div>
                </div>
              </div>

              <div className={`${styles.OneThird}`}>
                <div className={`${styles.OneThirdUnder}`}>
                  <img src="/images/freturesImg3.jpg" className={`${styles.freturesImg}`} alt="" />
                  <div className={`${styles.freturesCon}`}>
                    <p className={`${styles.freturesConTitle} ${styles.Heading}`}>AIR FREIGHT</p>
                    <p className={`${styles.freturesConText}`}>We provide full supply chain management pack-
                      age including cost effective and fast sea freight.
                      You can also combine this package with other
                      means of transportation.</p>
                  </div>
                </div>
              </div>

            </div>
            <button className={`${styles.FeatresBU}`}>Learn More</button>
          </div>
        </div>

        <div className={`${styles.Offers}`}>
          <div className={`${styles.Container}`}>
            <p className={`${styles.OffersTitle} ${styles.Heading}`}>What we offer</p>
            <p className={`${styles.OffersTitleSM}`}>Lorem ipsum dolor sit amet</p>
            <div className={`${styles.Row}`}>

              <div className={`${styles.OffersHalf}`}>
                <div className={`${styles.OffersHalfUnder}`}>
                  <img src="/images/offerIcon1.svg" alt="" />
                  <div>
                    <p className={`${styles.OfferCardTitle}`}>Contract logistics</p>
                    <p className={`${styles.OfferCardText}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                  </div>
                </div>
              </div>

              <div className={`${styles.OffersHalf}`}>
                <div className={`${styles.OffersHalfUnder}`}>
                  <img src="/images/offerIcon2.svg" alt="" />
                  <div>
                    <p className={`${styles.OfferCardTitle}`}>Overland, Ocean and Air Freight</p>
                    <p className={`${styles.OfferCardText}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                  </div>
                </div>
              </div>

              <div className={`${styles.OffersHalf}`}>
                <div className={`${styles.OffersHalfUnder}`}>
                  <img src="/images/offerIcon3.svg" alt="" />
                  <div>
                    <p className={`${styles.OfferCardTitle}`}>Warehousing and Storage</p>
                    <p className={`${styles.OfferCardText}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                  </div>
                </div>
              </div>

              <div className={`${styles.OffersHalf}`}>
                <div className={`${styles.OffersHalfUnder}`}>
                  <img src="/images/offerIcon4.svg" alt="" />
                  <div>
                    <p className={`${styles.OfferCardTitle}`}>Consulting Services</p>
                    <p className={`${styles.OfferCardText}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className={`${styles.WhyChooseSec}`} style={{ background: 'none' }}>
          <div className={`${styles.WhyChooseHalf}`}>
            <div className={`${styles.WhyContainer}`}>
              <p className={`${styles.WhyChooseTitle} ${styles.Heading}`} style={{ color: '#383838' }}>WHY CHOOSE Agyakoo Shipping?</p>
              <ul>
                <li>
                  <p className={`${styles.WhyChooseListTitle}`}>Fast Worldwide delivery</p>
                  <p className={`${styles.WhyChooseListText}`} style={{ color: '#383838' }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </li>
                <li>
                  <p className={`${styles.WhyChooseListTitle}`}>End-to-end solution available</p>
                  <p className={`${styles.WhyChooseListText}`} style={{ color: '#383838' }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </li>
                <li>
                  <p className={`${styles.WhyChooseListTitle}`}>Safety & Compliance</p>
                  <p className={`${styles.WhyChooseListText}`} style={{ color: '#383838' }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </li>
              </ul>
              <button className={`${styles.WhyChooseBU}`}>GET A QUICK QUOTE</button>
            </div>
          </div>
          <div className={`${styles.WhyChooseHalf}`}>
            <img src="/images/why-choose.jpg" className={`${styles.SideIMG}`} alt="" />
          </div>
        </div>

        {/*<div className={`${styles.Offers}`}>
          <img src="/images/deliveryBG.svg" className={`${styles.OffersBG}`} alt="" />
          <div className={`${styles.Container}`}>
            <p className={`${styles.OffersTitle} ${styles.Heading}`}>Testimonial</p>
            <p className={`${styles.OffersTitleSM}`}>What our client say</p>
            <Carousel responsive={testimonial} className={`${styles.Testimonial}`}
            swipeable={true}
            arrows={false}
            draggable={true}
            showDots={false}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={props.deviceType !== "mobile" ? true : false}
            autoPlay={true}
            autoPlaySpeed={2500}
            keyBoardControl={true}
            customTransition="2000ms all"
            transitionDuration={300}>
              <div className={`${styles.item}`}>
                <div className={`${styles.TestiHead}`}>
                  <img src="/images/testi1.png" alt="" />
                  <div>
                    <p className={`${styles.TestiName}`}>Winifred Nunez</p>
                    <p className={`${styles.TestiDes}`}>CEO of Lapsas</p>
                  </div>
                </div>
                <p className={`${styles.TestiCon}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
              </div>
              <div className={`${styles.item}`}>
                <div className={`${styles.TestiHead}`}>
                  <img src="/images/testi2.png" alt="" />
                  <div>
                    <p className={`${styles.TestiName}`}>Winifred Nunez</p>
                    <p className={`${styles.TestiDes}`}>CEO of Lapsas</p>
                  </div>
                </div>
                <p className={`${styles.TestiCon}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
              </div>
              <div className={`${styles.item}`}>
                <div className={`${styles.TestiHead}`}>
                  <img src="/images/testi3.png" alt="" />
                  <div>
                    <p className={`${styles.TestiName}`}>Winifred Nunez</p>
                    <p className={`${styles.TestiDes}`}>CEO of Lapsas</p>
                  </div>
                </div>
                <p className={`${styles.TestiCon}`}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
              </div>
            </Carousel>
          </div>
        </div>*/}

      </React.Fragment>
    )
  }
}

export default withNavigateHook(Home);